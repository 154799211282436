.loader {
    position: relative;
    display: flex;
    height: 80vh;
    width: 100%;
    justify-content: center;
    align-items: center;

    .loading {
        width: 100px;
        height: 100px;
        background-color: red;
        position: relative;
        animation-name: example;
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }
}

@keyframes example {
    0% {
        background-color: red;
        left: -100px;
        top: -100px;
    }
    25% {
        background-color: yellow;
        left: 100px;
        top: -100px;
    }
    50% {
        background-color: blue;
        left: 100px;
        top: 100px;
    }
    75% {
        background-color: green;
        left: -100px;
        top: 100px;
    }
    100% {
        background-color: red;
        left: -100px;
        top: -100px;
    }
}
