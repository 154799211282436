.content {
  height: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 0.7;
  position: absolute;
  min-width: 8%;
  padding: 0px 10px;

  .title {
    font-weight: bold;
    font-size: 20px;
    color: #4a4a4a;
    
  }

  .subtitle {
    font-weight: lighter;
    font-size: 12px;
   display: none;
  }
}

.menu-item {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 285px;
  margin: 0 7.5px 15px;
  overflow: hidden;
  border-radius: 2px;
  min-width: 30%;
  &.large {
    height: 360px;
    min-width: 45%;
  }

  &:hover {
    cursor: pointer;
  

    & .background-image {
      transform: scale(1.1);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }
    & .content {
      opacity: 0.9;
    }
    & .title{
      margin-bottom: 0px;
      margin-top: 5px;
    }
    & .content {
      .subtitle {
        display: block;
      }
    }
  }


  .background-image {
    width: 100%;
    height: 100%;
    background-position: center top;
    background-size: cover;
  }
}
  @media (max-width: 510px) {
    .menu-item{
      min-width: 50%;
      &.large{
        min-width: 50%;
      }
    }
    
  }