.header {
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: space-between;
 align-items: center;
  z-index: 100;
  border-bottom: 0.8px solid rgb(165, 165, 165);
  top: 0;
  background-color: white;
  min-height: 10vh;
  padding: 10px 0px;

  .logo-container {
    height: 100%;
    width: 70px;
    margin-left: 7vw;
    padding-top: 5px;
  }
  .logo {
    height: 65px;
    width: 74px;
  }
  .options {
    display: inline-block;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
 

    .option {
      margin: auto;
      cursor: pointer;
      padding: 0px 10px;
    }
    .option:hover {
      color: #e50051;
    }
  }
}
@media (max-width: 444px) {
  .header {
    padding: 10px 0px;

    .logo-container {
      height: 100%;
      width: 70px;
      margin-left: 10px;
    }
    .options {
      padding-top: 13px;
      .option {
        padding: 10px 8px;
      }
    }
  }
}
