.popup {
 display: flex;
 
  width: 100%;
  height: 100%;
  background-color: rgba($color: gray, $alpha: 0.5);
  position: fixed;
  z-index: 150;
  justify-content: center;
  align-items: center;
}

.popupCard {
  border-radius: 10px;
  background-color: white;
  padding: 14px 18px;
  margin: auto;
}

.guest {
  margin: 15px 15px 5px;
}
.createBtn {
  margin: 15px 15px 5px;
  cursor: pointer;
  text-decoration: underline;
}
.btnGroup {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}
