.cart-icon {
    width: 45px;
    height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 6.2vw;
  
  
    .shopping-icon {
      width: 24px;
      height: 24px;
      color: #E50051;
    }
  
    .item-count {
      position: absolute;
      font-size: 10px;
      font-weight: bold;
      bottom: 12.5px;
      margin: auto;
    }
  }

  @media (max-width: 444px) {

    .cart-icon{
      margin-right: 8px;
    }
    
  }