.custom-button {
  min-width: 160px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 30px;
  font-size: 15px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: "Open Sans Condensed";
  font-weight: bolder;
  border: 1px solid #3a49ee;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: white;
    color: black;
    border: 1px solid #e50051;
  }

  &.google-sign-in {
    border: none;
    background-color: #3a49ee;
    color: white;

    &:hover {
      background-color: #3644d6;
      border: none;
    }
  }

  &.inverted {
    background-color: white;
    color: black;
    border: 1px solid black;

    &:hover {
      background-color: black;
      color: white;
      border: none;
    }
  }
}
@media (max-width:600px) {
  .custom-button{
    padding: 0 20px;
  }
  
}