.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 150px;
  margin: auto;
  min-height: 80vh;
}

.directory-menu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


@media (min-width: 1440px) {
  .homepage {
   
    padding: 25px 265px;
  }
}

@media (max-width: 1000px) {
  .homepage {
   
    padding: 25px 35px;
  }
}

@media (max-width: 400px) {
  .menu-item{
    width: 50%;
  }
}
