.footer {
  display: flex;
  bottom: 0;
  color: #ddd;
  background: linear-gradient(to right, #e50051 25%, #3a49ee);
  min-height: 10vh;
  align-items: center;
  justify-content: center;
}

.footer .title {
  margin: auto;
  padding-right: 22vw;
}

.author{
  margin:0px;
  width: 20vw;
  font-size: 12px;
  padding-left: 10px;
}